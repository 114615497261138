import { actionType } from "constants/Tasks";
import { omitBy, isNil } from "lodash";
import moment from "moment";
import { axiosProxy } from "utils/axiosProxy";
import genericAttachmentUploader from "utils/genericAttachmentUploader";
import { getRefreshToken } from "actions/User";
import uuid from "uuid/v4";
let uniqId;
const url = "/general_task";
export const addSelectedTaskType = data => async dispatch => {
  await dispatch({
    type: actionType.TASKS_TYPE_LIST,
    payload: data
  });
};

export const fetchGeneralTaskList = () => async dispatch => {
  try {
    dispatch({ type: actionType.FETCH_GENERAL_TASK_LIST_START });
    const res = await axiosProxy({
      method: "GET",
      url,
      params: {
        unpaged: true
      }
    });
    await dispatch({
      type: actionType.FETCH_GENERAL_TASK_LIST,
      payload: res.data
    });
  } catch (error) {
    dispatch({ type: actionType.FETCH_GENERAL_TASK_LIST_FAIL, payload: error });
  }
};

export const fetchGeneralTaskTableList = ({
  archived,
  search,
  filters
}) => async dispatch => {
  try {
    dispatch({ type: actionType.FETCH_GENERAL_TASK_TABLE_LIST_START });

    const params = new URLSearchParams({ unpaged: true });

    if (archived !== undefined) {
      params.append("archived", archived);
    }

    if (search !== undefined) {
      params.append("search", search);
    }

    if (Array.isArray(filters)) {
      filters.forEach(([filter, value]) => {
        const from = value.slice(0, 10);
        const to = value.slice(-10);
        return filter !== "seasons"
          ? params.append(filter, value)
          : (params.append("from", `${from}T00:00:00`),
            params.append("to", `${to}T23:59:59`));
      });
    }

    const res = await axiosProxy({
      method: "GET",
      url,
      params
    });

    await dispatch({
      type: actionType.FETCH_GENERAL_TASK_TABLE_LIST,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: actionType.FETCH_GENERAL_TASK_TABLE_LIST_FAIL,
      payload: error
    });
  }
};

export const fetchSubTaskList = () => async dispatch => {
  try {
    dispatch({ type: actionType.FETCH_SUBTASK_LIST_START });
    const res = await axiosProxy({
      method: "GET",
      url: "/general_task/subetasks"
    });
    await dispatch({
      type: actionType.FETCH_SUBTASK_LIST,
      payload: res.data
    });
  } catch (error) {
    dispatch({ type: actionType.FETCH_SUBTASK_LIST_FAIL, payload: error });
  }
};
export const fetchGeneralTask = (
  { archived, page, size, search, sort, unpaged = false, filters },
  selectedTab,
  activeTab,
  taskTableType
) => async dispatch => {
  try {
    dispatch({ type: actionType.FETCH_TASKS_START });
    const params = new URLSearchParams();
    if (archived !== undefined) {
      params.append("archived", archived);
    }
    if (page !== undefined) {
      params.append("page", page);
    }
    if (size !== undefined) {
      params.append("size", size);
    }
    if (search !== undefined) {
      params.append("search", search);
    }
    if (unpaged !== undefined) {
      params.append("unpaged", unpaged);
    }
    const sortCriteria = sort ? [...sort] : [];
    if (!sortCriteria.includes("expired,desc")) {
      sortCriteria.unshift("expired,desc");
    }
    sortCriteria.forEach(sortCriterion => {
      params.append("sort", sortCriterion);
    });
    if (Array.isArray(filters)) {
      filters.forEach(([filter, value]) => {
        const from = value.slice(0, 10);
        const to = value.slice(-10);
        return filter !== "seasons"
          ? params.append(filter, value)
          : (params.append("from", `${from}T00:00:00`),
            params.append("to", `${to}T23:59:59`));
      });
    }
    if (selectedTab && selectedTab.value !== 0) {
      params.append("taskTypeId", selectedTab.value);
    }
    if (activeTab && activeTab !== "EXPIRED" && taskTableType === "list") {
      params.append("status", activeTab);
    }

    const localTime = moment().format("YYYY-MM-DDTHH:mm:ss");
    if (activeTab === "EXPIRED" && taskTableType === "list") {
      params.append("status", "OPEN");
      params.append("status", "IN_PROGRESS");
      params.append("expireAt", localTime);
    }

    const uniqId = uuid();

    const res = await axiosProxy({
      method: "GET",
      url,
      params,
      headers: { "X-REQUEST-ID": uniqId }
    });

    if (uniqId === res.config.headers["X-REQUEST-ID"]) {
      await dispatch({
        type: actionType.FETCH_TASKS,
        payload: res.data
      });
    }

    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.FETCH_TASKS_FAIL,
      payload: error
    });
  }
};
export const createGeneralTask = data => async dispatch => {
  try {
    dispatch({ type: actionType.CREATE_GENERAL_TASK_START });

    const res = await axiosProxy({ method: "POST", url, data });

    dispatch({ type: actionType.CREATE_GENERAL_TASK, payload: res.data });

    return res;
  } catch (e) {
    dispatch({ type: actionType.CREATE_GENERAL_TASK_FAIL, payload: e });
  }
};
export const updateGeneralTask = (taskID, data) => async dispatch => {
  try {
    dispatch({ type: actionType.CREATE_GENERAL_TASK_START });

    const res = await axiosProxy({
      method: "PUT",
      url: `${url}/${+taskID}`,
      data
    });

    dispatch({ type: actionType.CREATE_GENERAL_TASK, payload: res.data });

    return res;
  } catch (e) {
    dispatch({ type: actionType.CREATE_GENERAL_TASK_FAIL, payload: e });
  }
};
export const deleteGeneralTask = (
  tasksIds,
  parameter,
  isArchivedView,
  selectedTab,
  activeTab,
  taskTableType
) => async dispatch => {
  try {
    dispatch({ type: actionType.DELETE_GENERAL_TASK_START });

    await axiosProxy({
      method: "DELETE",
      url: `${url}/batch?ids=${tasksIds.join(",")}`
    });
    dispatch(
      fetchGeneralTask(
        { ...parameter, archived: isArchivedView },
        selectedTab,
        activeTab,
        taskTableType
      )
    );
    dispatch({
      type: actionType.DELETE_GENERAL_TASK,
      payload: tasksIds
    });
  } catch (res) {
    dispatch({ type: actionType.DELETE_GENERAL_TASK_FAIL, payload: res });
  }
};
export const changeArchivedStatus = (
  tasksIds,
  archive,
  parameter,
  isArchivedView,
  selectedTab,
  activeTab,
  taskTableType
) => async dispatch => {
  try {
    dispatch({
      type: actionType.FETCH_TASKS_ARCHIVE_START
    });

    await axiosProxy({
      method: "PUT",
      url: `${url}/archive/batch?ids=${tasksIds.join(",")}&archive=${archive}`
    });

    dispatch(
      fetchGeneralTask(
        { ...parameter, archived: isArchivedView },
        selectedTab,
        activeTab,
        taskTableType
      )
    );
    dispatch({
      type: actionType.FETCH_TASKS_ARCHIVE,
      payload: tasksIds
    });
  } catch (error) {
    dispatch({
      type: actionType.FETCH_TASKS_ARCHIVE_FAIL,
      payload: error
    });
  }
};
export const setActiveItemId = id => dispatch => {
  dispatch({ type: actionType.SET_ACTIVE_ITEM_ID, payload: id });
};
export const setSelectedTab = item => dispatch => {
  dispatch({ type: actionType.SET_ACTIVE_TAB, payload: item });
};
export const setSelectAddType = item => dispatch => {
  dispatch({ type: actionType.SET_ADDED_TYPE, payload: item });
};
export const setSelectTableType = item => dispatch => {
  dispatch({ type: actionType.SET_TABLE_TYPE, payload: item });
};
export const fetchGeneralTasksIDs = () => async dispatch => {
  try {
    dispatch({ type: actionType.FETCH_GENERAL_TASK_IDS_START });

    const res = await axiosProxy({ method: "GET", url: `${url}/ids` });

    dispatch({
      type: actionType.FETCH_GENERAL_TASK_IDS,
      payload: { content: res.data }
    });
  } catch (error) {
    dispatch({ type: actionType.FETCH_GENERAL_TASK_IDS_FAIL, payload: error });
  }
};

export const fetchGeneralTasksNames = () => async dispatch => {
  try {
    dispatch({ type: actionType.FETCH_GENERAL_TASK_NAMES_START });

    const res = await axiosProxy({ method: "GET", url: `${url}/names` });
    dispatch({
      type: actionType.FETCH_GENERAL_TASK_NAMES,
      payload: { content: res.data }
    });
  } catch (error) {
    dispatch({
      type: actionType.FETCH_GENERAL_TASK_NAMES_FAIL,
      payload: error
    });
  }
};

export const fetchTaskPaymentMethods = () => async dispatch => {
  try {
    dispatch({ type: actionType.FETCH_TASK_PAYMENT_METHODS_START });

    const params = new URLSearchParams();
    params.append("unpaged", "true");

    const res = await axiosProxy({
      method: "GET",
      url: `${url}/payment_methods`
    });

    await dispatch({
      type: actionType.FETCH_TASK_PAYMENT_METHODS,
      payload: res.data
    });
    return res.data;
  } catch (e) {
    dispatch({
      type: actionType.FETCH_TASK_PAYMENT_METHODS_ERROR,
      payload: e
    });
  }
};
export const changeActiveTab = load => async dispatch => {
  dispatch({ type: actionType.CHANGE_ACTIVE_TAB, payload: load });
};

export const clearError = () => dispatch =>
  dispatch({ type: actionType.CLEAR_ERROR });

export const fetchGeneralTasksWorkingTimes = ({
  dateFrom,
  dateTo,
  id,
  filters = {},
  unpaged = true
}) => async (dispatch, getState) => {
  const { areas, employee, employeeGroups, contractors } = getState();
  try {
    dispatch({ type: actionType.GET_GENERAL_TASK_WORKING_TIME_START });

    const params = new URLSearchParams();

    if (unpaged) {
      params.append("unpaged", "true");
    }

    for (const name in filters) {
      const value = filters[name];

      if (value) {
        params.append(name, value.toString());
      }
    }

    dateFrom && params.append("endAtAfter", `${dateFrom}T00:00:00`);
    dateTo && params.append("endAtBefore", `${dateTo}T23:59:59`);
    const res = await axiosProxy({
      method: "GET",
      params,
      url: "/general_task/working_times"
    });
    const workingTimes = res.data.content?.map(item => {
      return {
        ...item,
        areas: areas.list.content
          ?.filter(area => item.areas.some(({ areaId }) => areaId === area.id))
          .map(filtredArea => {
            return {
              area: filtredArea,
              areaId: filtredArea.id
            };
          }),
        contractor: contractors.list.content.find(
          contractor => item.contractorId === contractor.id
        ),
        employee: employee.list.content.find(
          employee => item.employeeId === employee.id
        ),
        employeesGroup: employeeGroups.list.content.find(
          employeeGroup => item.employeesGroupId === employeeGroup.id
        )
      };
    });
    dispatch({
      type: actionType.GET_GENERAL_TASK_WORKING_TIME,
      payload: {
        taskId: id,
        workingTimes
      }
    });
    return res.data;
  } catch (e) {
    dispatch({
      type: actionType.GET_GENERAL_TASK_WORKING_TIME_FAIL,
      payload: e
    });
  }
};

export const uploadAttachment = ({
  file,
  idReplace,
  callBack,
  data,
  parentId
}) =>
  genericAttachmentUploader({
    url: `${url}/attachments/presign_put_url`,
    file,
    startAction: actionType.UPLOAD_GENERAL_TASKS_ATTACHMENTS_START,
    successAction: actionType.UPLOAD_GENERAL_TASKS_ATTACHMENTS,
    errorAction: actionType.UPLOAD_GENERAL_TASKS_ATTACHMENTS_ERROR,
    callBack,
    idReplace,
    data,
    parentId
  });

export const deleteAttachment = id => dispatch =>
  dispatch({ type: actionType.DELETE_ATTACHMENT, payload: id });

export const fetchGeneralTaskById = id => async (dispatch, getState) => {
  const {
    areas,
    employee,
    sprayDiary,
    contractors,
    employeeGroups
  } = getState();
  try {
    dispatch({ type: actionType.FETCH_GENERAL_TASK_START });
    const res = await axiosProxy({ method: "GET", url: `${url}/${id}` });

    const newData = {
      ...res.data,
      areas: res.data.areas.map(area => ({
        ...area,
        area: areas.list.content.find(a => area.areaId === a.id)
      })),
      assignedTo: employee.list.content
        ?.filter(employee =>
          res.data.assignedTo.some(
            ({ employeeId }) => employeeId === employee.id
          )
        )
        ?.map(filtredEmployee => {
          return {
            employee: filtredEmployee,
            employeeId: filtredEmployee.id
          };
        }),
      equipments: sprayDiary.machineryList.content
        ?.filter(machinery =>
          res.data.equipments.some(
            ({ machineryId }) => machineryId === machinery.id
          )
        )
        ?.map(filtredMachinery => {
          return {
            machinery: filtredMachinery,
            machineryId: filtredMachinery.id
          };
        }),
      workingTimes: res.data.workingTimes?.map(item => {
        return {
          ...item,
          areas: areas.list.content
            ?.filter(area =>
              item.areas.some(({ areaId }) => areaId === area.id)
            )
            ?.map(filtredArea => {
              return {
                area: filtredArea,
                areaId: filtredArea.id
              };
            }),
          contractor: contractors.list.content.find(
            contractor => item.contractorId === contractor.id
          ),
          employee: employee.list.content.find(
            employee => item.employeeId === employee.id
          ),
          employeesGroup: employeeGroups.list.content.find(
            employeeGroup => item.employeesGroupId === employeeGroup.id
          )
        };
      })
    };
    await dispatch({ type: actionType.FETCH_GENERAL_TASK, payload: newData });
    return newData;
  } catch (error) {
    dispatch({ type: actionType.FETCH_GENERAL_TASK_FAIL, payload: error });
  }
};

export const deleteGeneralTaskWorkingTime = (taskId, id) => async dispatch => {
  try {
    dispatch({ type: actionType.GENERAL_TASK_WORKING_TIME_DELETE_START });
    const res = await axiosProxy({
      method: "DELETE",
      url: `/general_task/working_times/${id}`
    });

    dispatch({
      type: actionType.GENERAL_TASK_WORKING_TIME_DELETE,
      payload: { id, taskId }
    });

    return res;
  } catch (e) {
    e.response?.status === 401 &&
      dispatch(getRefreshToken()).then(
        dispatch(deleteGeneralTaskWorkingTime(id))
      );
    dispatch({
      type: actionType.GENERAL_TASK_WORKING_TIME_DELETE_FAIL,
      payload: e
    });
  }
};

export const updateWorkingTime = (id, data) => async (dispatch, getState) => {
  const {
    areas,
    employee,
    sprayDiary,
    contractors,
    employeeGroups
  } = getState();
  try {
    dispatch({
      type: actionType.GENERAL_TASK_WORKING_TIME_UPDATE_START,
      payload: { id }
    });

    const res = await axiosProxy({
      method: "PUT",
      data,
      url: `/general_task/working_times/${id}`
    });
    const newData = {
      ...res.data,
      areas: areas.list.content
        ?.filter(area =>
          res.data.areas.some(({ areaId }) => areaId === area.id)
        )
        .map(filtredArea => {
          return {
            area: filtredArea,
            areaId: filtredArea.id
          };
        }),
      contractor: contractors.list.content.find(
        contractor => res.data.contractorId === contractor.id
      ),
      employee: employee.list.content.find(
        employee => res.data.employeeId === employee.id
      ),
      employeesGroup: employeeGroups.list.content.find(
        employeeGroup => res.data.employeesGroupId === employeeGroup.id
      )
    };

    dispatch({
      type: actionType.GENERAL_TASK_WORKING_TIME_UPDATE,
      payload: newData
    });
    return res;
  } catch (e) {
    dispatch({
      type: actionType.GENERAL_TASK_WORKING_TIME_UPDATE_FAIL,
      payload: e
    });
    e.response.status === 401 &&
      dispatch(getRefreshToken()).then(dispatch(updateWorkingTime(id, data)));
    return e.response;
  }
};

export const createWorkingTime = ({ formData }) => async (
  dispatch,
  getState
) => {
  const {
    areas,
    employee,
    sprayDiary,
    contractors,
    employeeGroups
  } = getState();
  try {
    dispatch({ type: actionType.GENERAL_TASK_WORKING_TIME_CREATE_START });

    const res = await axiosProxy({
      method: "POST",
      url: "/general_task/working_times",
      data: formData
    });
    const newData = {
      ...res.data,
      areas: areas.list.content
        ?.filter(area =>
          res.data.areas.some(({ areaId }) => areaId === area.id)
        )
        .map(filtredArea => {
          return {
            area: filtredArea,
            areaId: filtredArea.id
          };
        }),
      contractor: contractors.list.content.find(
        contractor => res.data.contractorId === contractor.id
      ),
      employee: employee.list.content.find(
        employee => res.data.employeeId === employee.id
      ),
      employeesGroup: employeeGroups.list.content.find(
        employeeGroup => res.data.employeesGroupId === employeeGroup.id
      )
    };

    dispatch({
      type: actionType.GENERAL_TASK_WORKING_TIME_CREATE,
      payload: { workingTime: newData }
    });
    return res;
  } catch (e) {
    dispatch({
      type: actionType.GENERAL_TASK_WORKING_TIME_CREATE_FAIL,
      payload: e
    });
    e.response.status === 401 &&
      dispatch(getRefreshToken()).then(dispatch(createWorkingTime(formData)));
    return e.response;
  }
};

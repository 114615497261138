import React from "react";
import PropTypes from "prop-types";
import { Grid, Label, Table } from "semantic-ui-react";

import style from "../index.css";
import moment from "moment";

const Line = ({ prop, value, unit = "" }) => (
  <Grid.Row className={"info-row"}>
    <Grid.Column computer={8}>
      <b>{prop}</b>
    </Grid.Column>
    <Grid.Column computer={8}>
      {value} {unit}
    </Grid.Column>
  </Grid.Row>
);

Line.propTypes = {
  prop: PropTypes.string,
  value: PropTypes.string,
  unit: PropTypes.string
};

const PatchInfo = ({ patch, className }) => {
  return (
    <Grid className={`margin-top-10 ${className}`}>
      <Grid.Row>
        <Grid.Column computer={9}>
          <Grid className={"test"}>
            <Line
              prop={"Size"}
              value={patch ? patch[0]?.size?.toFixed(2) : null}
              unit={"Ha"}
            />
            <Table className={style.table}>
              <Table.Header className={style.tableHeader}>
                <Table.HeaderCell className="mediumCell">Crop</Table.HeaderCell>
                <Table.HeaderCell className="mediumCell">
                  Variety
                </Table.HeaderCell>
                <Table.HeaderCell className="largeCell">
                  Plant date
                </Table.HeaderCell>
                <Table.HeaderCell className="largeCell">
                  Picking date
                </Table.HeaderCell>
                <Table.HeaderCell className="smallCell">
                  Number of plants
                </Table.HeaderCell>
              </Table.Header>
              <Table.Body className={style.tableBody}>
                {patch.map(item => {
                  return (
                    <Table.Row>
                      <Table.Cell>{item.currentCrop}</Table.Cell>
                      <Table.Cell>{item.variety}</Table.Cell>
                      <Table.Cell>{item.plantedDate}</Table.Cell>
                      <Table.Cell>{item.pickingAt}</Table.Cell>
                      <Table.Cell>{item.numberOfPlants}</Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Grid>
        </Grid.Column>
        <Grid.Column className={style.dateContainer} computer={7}>
          <Grid>
            {patch[0].withholdingDays > 0 && (
              <>
                <Label
                  style={{
                    width: "160px",
                    textAligne: "start",
                    marginBottom: "20px",
                    marginLeft: "0px"
                  }}
                  color="red"
                >
                  Not Safe to Pick
                </Label>

                <span>
                  Until date:{" "}
                  {moment(`${patch[0].withholdingPeriodDate}Z`).format(
                    "DD/MM/YYYY"
                  )}
                </span>
              </>
            )}
            {patch[0].reentryDays > 0 && (
              <>
                <Label
                  style={{
                    width: "160px",
                    textAligne: "start",
                    marginLeft: "0px"
                  }}
                  color="red"
                >
                  Not Safe to Enter
                </Label>

                <span>
                  Until date:{" "}
                  {moment(`${patch[0].reentryDate}Z`).format("DD/MM/YYYY")}
                </span>
              </>
            )}
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

PatchInfo.propTypes = {
  patch: PropTypes.object,
  className: PropTypes.string
};

PatchInfo.defaultProps = {
  className: ""
};

export default PatchInfo;

import React from "react";
import { Icon, Input } from "semantic-ui-react";
import styles from "../AddEditTask/AddTask.module.css";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import moment from "moment/moment";

const DateTimePicker = ({ values, setFieldValue }) => {
  return (
    <DatePicker
      onChange={value => {
        const date = new Date(value?.toDate?.().toString());
        const formattedDate = moment(date).format("YYYY-MM-DDTHH:mm:ss");
        setFieldValue("expireAt", formattedDate);
      }}
      value={
        values.expireAt
          ? `${moment(values.expireAt).format("DD/MM/YYYY HH:mm:ss")}`
          : null
      }
      className={styles.expirCalendar}
      format="DD/MM/YYYY HH:mm"
      placeholder="dd/mm/yyyy hh:mm:ss"
      buttons={false}
      plugins={[
        <TimePicker
          style={{ maxWidth: "250px" }}
          disableClock={true}
          hideSeconds
          position="bottom"
        />
      ]}
      render={
        <Input
          placeholder={"dd/mm/yyyy"}
          icon={<Icon name="calendar outline" />}
          iconPosition="right"
        />
      }
    />
  );
};
export default DateTimePicker;

import React from "react";
import styles from "./CardTable.module.css";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { Chip } from "routes/Reports/Harvest/components/Chip";
import { Dropdown, Icon, Checkbox } from "semantic-ui-react";
import { employeeViewer } from "routes/Tasks/components/helper";
import { priorityIcon } from "../helpers";
import { tasksPriorityFilter } from "selectors/generalTasks";

const TaskCard = ({
  onRowClick,
  changeTaskStatus,
  progressPrecent,
  isArchivedView,
  handleTaskIdClick,
  onArchiveClick,
  onDeleteClick,
  onTaskPrint,
  areasList,
  employeeList,
  item,
  localTime,
  color,
  selectMode,
  selectedItemsIds,
  handleSelectChange,
  activeTabName,
  activeTab,
  navigate
}) => {
  const filtredArea = areasList.filter(a =>
    item.areas.some(area => area.areaId === a.id)
  );
  const filtredFarm = filtredArea.map(item => item.farm);
  const uniqueArray = Array.from(
    new Set(filtredFarm.map(obj => obj.id))
  ).map(id => filtredFarm.find(item => item.id === id));
  const handleDragStart = (e, itemId) => {
    e.dataTransfer.setData("itemId", itemId);
  };

  const prioritiesList = useSelector(tasksPriorityFilter).options.map(item => ({
    key: item.key,
    text: item.label,
    value: item.value
  }));
  return (
    <div
      draggable="true"
      onDragStart={e => handleDragStart(e, item.id)}
      className={styles.card}
    >
      <div
        style={{
          backgroundColor:
            item.expireAt < localTime && activeTabName !== "COMPLETED"
              ? "#B00020"
              : color
        }}
        className={styles.precentSection}
      >
        {item.expireAt < localTime && activeTabName !== "COMPLETED"
          ? "Expired"
          : progressPrecent}
      </div>
      <div className={styles.checkboxWrapper}>
        {selectMode && activeTabName === activeTab && (
          <div
            className={styles.lineCheckbox}
            onClick={() => handleSelectChange(item.id)}
          >
            {selectedItemsIds.includes(item.id) ? (
              <Icon
                className={styles.checkMarkIcon}
                name="check circle outline"
              />
            ) : (
              <Icon className={styles.checkMarkIcon} name="circle outline" />
            )}
          </div>
        )}
        <span style={{ width: "100%" }}>
          <div className={styles.taskNameSection}>
            {item.name}
            <Dropdown
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "#F7F7F7",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                paddingBottom: "5px",
                paddingLeft: "7px",
                borderRadius: "100%",
                color: "#0E9354"
              }}
              icon="ellipsis horizontal"
              iconposition="right"
              floating
            >
              <Dropdown.Menu direction="left">
                <Dropdown.Item
                  onClick={async () => (
                    await onRowClick(item.id), navigate("/tasks/add")
                  )}
                >
                  Edit
                </Dropdown.Item>
                <Dropdown.Item onClick={() => onTaskPrint(item.id)}>
                  Print
                </Dropdown.Item>
                <Dropdown.Item onClick={() => onArchiveClick(item.id)}>
                  {!isArchivedView ? "Archive" : "Restore"}
                </Dropdown.Item>
                {isArchivedView && (
                  <Dropdown.Item onClick={() => onDeleteClick(item.id)}>
                    Delete
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className={styles.methodSection}>
            <span className={styles.method}>
              {(
                item.paymentMethod
                  .toLowerCase()
                  .charAt(0)
                  .toUpperCase() + item.paymentMethod.toLowerCase().slice(1)
              ).replace(/_/g, " ") || "-"}{" "}
              •{" "}
            </span>
            <Link
              onClick={() => (onRowClick(item.id), handleTaskIdClick(item.id))}
              to={`/tasks/slashing/${item.id}`}
            >
              GT-{item.id}
            </Link>
          </div>
        </span>
      </div>
      <div>
        <span className={styles.headerSection}>Date</span>
        <span className={styles.valueSection}>
          {`${moment(item.startAt).format("DD/MM/YYYY")}-${moment(
            item.endAt
          ).format("DD/MM/YYYY")}`}
        </span>
      </div>
      <div>
        <span className={styles.headerSection}>Farm</span>
        <span className={styles.valueSection}>
          {uniqueArray.length > 1 ? (
            <Chip
              color="green"
              label="Farms"
              items={uniqueArray.map(area => area.name)}
            />
          ) : (
            uniqueArray[0].name
          )}
        </span>
      </div>
      <div>
        <span className={styles.headerSection}>Areas</span>
        <span className={styles.valueSection}>
          {" "}
          <Chip
            color="green"
            label="Areas"
            items={areasList
              .filter(a => item.areas.map(i => i.areaId).includes(a.id))
              .map(area => area.name)}
          />
        </span>
      </div>
      <div className={styles.employeeSection}>
        <div>{employeeViewer(item.supervisors, employeeList)}</div>
        {
          <div className={styles.iconSectiom}>
            <Dropdown
              pointing="right"
              text={item && priorityIcon(item.priority)}
              value={item.priority}
              icon={null}
              className={styles.dropdownStyle}
              options={prioritiesList}
              onChange={(_, { value }) =>
                changeTaskStatus(item.id, { ...item, priority: value })
              }
            />
          </div>
        }
      </div>
    </div>
  );
};

TaskCard.propTypes = {};
export default connect((state, props) => {
  return {
    areasList: state.areas.list.content,
    employeeList: state.employee.list.content,
    tableData: state.newTasks.data,
    isFetching: state.newTasks.isFetching
  };
})(TaskCard);

import React, { useState } from "react";
import { Dropdown, Input, Icon, Button } from "semantic-ui-react";
import { map } from "lodash";
import styles from "./AreasList.module.css";

const AreasList = ({ text, className, onChange, options, disabled }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const filtered = options?.filter(item =>
    item.text.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Dropdown
      icon={<Icon className={styles.dropdonwIcon} name="chevron down" />}
      text={text}
      selection
      fluid
      multiple
      search
      onClose={() => (setSearchQuery(""), setIsOpen(false))}
      className={styles.dropdownCustomStyle}
      searchQuery={searchQuery}
      closeOnChange={false}
      disabled={disabled}
      open={isOpen}
      onOpen={() => setIsOpen(true)}
    >
      <Dropdown.Menu className={styles.menuContainer}>
        <div className={styles.searchContainer}>
          <Input
            placeholder="Search"
            icon="search"
            iconPosition="left"
            className={styles.searchInput}
            onClick={e => e.stopPropagation()}
            onChange={e => setSearchQuery(e.target.value)}
            value={searchQuery}
          />
          <Button
            type="text"
            onClick={(e, data) => {
              e.preventDefault();
              const areasIds = (filtered || options).map(item => item.value);
              setIsOpen(false);
              onChange(e, areasIds);
            }}
          >
            Select all
          </Button>
        </div>
        <Dropdown.Menu scrolling>
          {map(
            (filtered || options).sort((a, b) => {
              const nameA = a.label?.trim().toLowerCase(),
                nameB = b.label?.trim().toLowerCase();
              if (nameA < nameB) return -1;
              if (nameA > nameB) return 1;
              return 0;
            }),
            option => (
              <Dropdown.Item
                key={option.value}
                value={option.value}
                text={option.text}
                onClick={(e, data) => onChange(e, data.value)}
              />
            )
          )}
        </Dropdown.Menu>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AreasList;

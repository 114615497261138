import React, { useEffect } from "react";
import { fetchTaskTypeList } from "actions/GeneralTasks/generalTasks";
import Calendar from "./components/FullCalendar";
import styles from "./TimeLine.module.css";
import { connect, useDispatch } from "react-redux";

const TimeLine = ({
  data,
  areasList,
  employeeList,
  onRowClick,
  handleTaskIdClick,
  tasksType
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    !tasksType.length && dispatch(fetchTaskTypeList());
  }, []);

  return (
    <div className={styles.calendarWrapper}>
      <Calendar
        tasksType={tasksType}
        handleTaskIdClick={handleTaskIdClick}
        onRowClick={onRowClick}
        data={data}
        areasList={areasList}
        employeeList={employeeList}
      />
    </div>
  );
};

export default connect(state => ({
  employeeList: state.employee.list.content,
  areasList: state.areas.list.content,
  data: state.newTasks.data,
  list: state.newTasks.list.content,
  isFetching: state.newTasks.isFetching,
  tasksType: state.generalTasks.tasksType
}))(TimeLine);

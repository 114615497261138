import React from "react";
import filterTypes from "components/Table/filterTypes";
import BasicCell from "components/Table/components/BasicCell";
import {
  harvestUnitsIdFilterOptions,
  rowFilterOptions,
  supervisorsFilterOptions,
  workersFilterOptions,
  harvestUnitOptionsSelector,
  internalBinIdOptions
} from "selectors/harvestUnits";
import { generalTasksFilterOptions } from "selectors/generalTasks";
import { Button, Icon, Popup } from "semantic-ui-react";
import style from "../routes/HarvestUnitsControl/HarvestUnitsControl.module.css";
import moment from "moment";
import { ROLE_COLORS } from "constants/InventoryMovements";
import { getNameInitials } from "routes/Labour/utils";
import DateRangeSelect from "components/Table/components/Filters/DateRangeSelect";
import { farmsOptionsFilterSelector } from "selectors/farms";
import { blocListSelector, patchListSelector } from "selectors/areas";
import { seasonsOptionsSelector } from "selectors/seasonsSelector";

export const getHarvestUnitsTableolumns = (
  editCheck,
  employees,
  setblockName,
  setimageModalOpen,
  setUrlList,
  areas,
  isFetching
) => {
  const columnsConfig = {
    id: {
      withSort: true,
      type: "number",
      title: "ID",
      id: "id",
      accessor: ({ id }) => {
        return id || "-";
      },
      cellWidth: 1
    },
    scannedAt: {
      withSort: true,
      type: "number",
      title: "Date",
      id: "scannedAt",
      accessor: ({ scannedAt }) => {
        return scannedAt ? moment(scannedAt).format("DD/MM/YYYY") : "—";
      },
      cellWidth: 1,
      filterId: "date",
      filter: {
        title: "Date",
        type: filterTypes.DateRangeSelect,
        selector: <DateRangeSelect loading={isFetching} />
      }
    },
    supervisor: {
      // withSort: true,
      type: "string",
      id: "supervisor",
      title: "Supervisor",
      accessor: ({ supervisors }) => {
        const numberOfEmployees = supervisors.length;
        const employeeIds = supervisors.map(item => item.employeeId);

        const morePopupContent = members => {
          return (
            <div className={style.morePopupContent}>
              {members.length &&
                members &&
                members.slice(3, members.length).map(emp => {
                  const employee =
                    (employees &&
                      emp &&
                      employees.find(employeeObj => employeeObj.id === emp)) ||
                    null;
                  const employeeImg =
                    employee && employee.picture
                      ? employee.picture.presignedUrl
                      : null;

                  return (
                    employee &&
                    !employee.archived && (
                      <div key={employee.id} className={style.popupItem}>
                        <div
                          className={style.avatarHolder}
                          style={
                            employeeImg
                              ? {
                                  backgroundImage:
                                    employeeImg && `url(${employeeImg})`
                                }
                              : {
                                  background: ROLE_COLORS[employee.type]
                                    ? ROLE_COLORS[employee.type]
                                    : "gray"
                                }
                          }
                        >
                          {!employeeImg && getNameInitials(employee)}
                        </div>
                        <span>{`${employee.firstName} ${employee.lastName}`}</span>
                      </div>
                    )
                  );
                })}
            </div>
          );
        };

        return numberOfEmployees ? (
          <ul className={style.avatarsContainer}>
            {employeeIds.slice(0, 3).map(employeeId => {
              const employee =
                (employees &&
                  employees.find(
                    employeeObj => employeeObj.id === employeeId
                  )) ||
                null;
              const employeeImg =
                employee && employee.picture
                  ? employee.picture.presignedUrl
                  : null;

              return employeeImg ? (
                <Popup
                  position="bottom center"
                  inverted
                  key={employee.id}
                  trigger={
                    <li
                      key={employee.id}
                      className={style.avatarHolder}
                      style={{ backgroundImage: `url(${employeeImg})` }}
                    />
                  }
                  content={`${employee.firstName} ${employee.lastName}`}
                />
              ) : (
                <Popup
                  key={employee && employee.id}
                  inverted
                  position="bottom center"
                  trigger={
                    <li
                      key={employee && employee.id}
                      className={style.avatarHolder}
                      style={{
                        background: ROLE_COLORS[employee && employee.type]
                          ? ROLE_COLORS[employee && employee.type]
                          : "gray"
                      }}
                    >
                      {getNameInitials(employee && employee)}
                    </li>
                  }
                  content={`${employee && employee.firstName} ${employee &&
                    employee.lastName}`}
                />
              );
            })}
            {numberOfEmployees > 3 && (
              <Popup
                inverted
                position="right center"
                popperModifiers={{
                  preventOverflow: {
                    boundariesElement: "offsetParent"
                  }
                }}
                content={morePopupContent(employeeIds)}
                trigger={<span>+{numberOfEmployees - 3}</span>}
              />
            )}
          </ul>
        ) : (
          "—"
        );
      },
      cellWidth: 1,
      filterId: "seasons",
      filter: {
        title: "Season",
        type: filterTypes.SingleSelect,
        selector: seasonsOptionsSelector
      }
    },
    employee: {
      id: "employee",
      // withSort: true,
      type: "string",
      title: "Workers",
      accessor: ({ employeeBinParts }) => {
        const numberOfEmployees = employeeBinParts.length;
        const employeeIds = employeeBinParts.map(item => item.employeeId);

        const morePopupContent = members => {
          return (
            <div className={style.morePopupContent}>
              {members.length &&
                members &&
                members.slice(3, members.length).map(emp => {
                  const employee =
                    (employees &&
                      emp &&
                      employees.find(employeeObj => employeeObj.id === emp)) ||
                    null;
                  const employeeImg =
                    employee && employee.picture
                      ? employee.picture.presignedUrl
                      : null;

                  return (
                    employee &&
                    !employee.archived && (
                      <div key={employee.id} className={style.popupItem}>
                        <div
                          className={style.avatarHolder}
                          style={
                            employeeImg
                              ? {
                                  backgroundImage:
                                    employeeImg && `url(${employeeImg})`
                                }
                              : {
                                  background: ROLE_COLORS[employee.type]
                                    ? ROLE_COLORS[employee.type]
                                    : "gray"
                                }
                          }
                        >
                          {!employeeImg && getNameInitials(employee)}
                        </div>
                        <span>{`${employee.firstName} ${employee.lastName}`}</span>
                      </div>
                    )
                  );
                })}
            </div>
          );
        };

        return numberOfEmployees ? (
          <ul className={style.avatarsContainer}>
            {employeeIds.slice(0, 3).map(employeeId => {
              const employee =
                (employees &&
                  employees.find(
                    employeeObj => employeeObj.id === employeeId
                  )) ||
                null;
              const employeeImg =
                employee && employee.picture
                  ? employee.picture.presignedUrl
                  : null;

              return employeeImg ? (
                <Popup
                  position="bottom center"
                  inverted
                  key={employee.id}
                  trigger={
                    <li
                      key={employee.id}
                      className={style.avatarHolder}
                      style={{ backgroundImage: `url(${employeeImg})` }}
                    />
                  }
                  content={`${employee.firstName} ${employee.lastName}`}
                />
              ) : (
                <Popup
                  key={employee && employee.id}
                  inverted
                  position="bottom center"
                  trigger={
                    <li
                      key={employee && employee.id}
                      className={style.avatarHolder}
                      style={{
                        background: ROLE_COLORS[employee && employee.type]
                          ? ROLE_COLORS[employee && employee.type]
                          : "gray"
                      }}
                    >
                      {getNameInitials(employee && employee)}
                    </li>
                  }
                  content={`${employee && employee.firstName} ${employee &&
                    employee.lastName}`}
                />
              );
            })}
            {numberOfEmployees > 3 && (
              <Popup
                inverted
                position="right center"
                popperModifiers={{
                  preventOverflow: {
                    boundariesElement: "offsetParent"
                  }
                }}
                content={morePopupContent(employeeIds)}
                trigger={<span>+{numberOfEmployees - 3}</span>}
              />
            )}
          </ul>
        ) : (
          "—"
        );
      },
      cellWidth: 1
    },
    farm: {
      id: "farm",
      // withSort: true,
      type: "string",
      title: "Farm",
      accessor: ({ areaId }) => {
        const area = areas.content.find(item => item.id === areaId);
        return area?.farm.name;
      },
      cellWidth: 1,
      filterId: "employee",
      filter: {
        title: "Worker",
        type: filterTypes.MultiSelect,
        selector: workersFilterOptions
      }
    },
    areaSort: {
      id: "areaSort",
      // withSort: true,
      type: "string",
      title: "Block/Patch",

      accessor: ({ areaId }) => {
        const area = areas.content.find(item => item.id === areaId);
        return areaId
          ? area?.type === "PATCH"
            ? `${area.parent?.name} - ${area?.name}`
            : `${area?.name}`
          : "-";
      },
      cellWidth: 1,
      filterId: "farmIds",
      filter: {
        title: "Farm",
        type: filterTypes.MultiSelect,
        selector: farmsOptionsFilterSelector
      }
    },
    row: {
      title: "Row",
      withSort: true,
      type: "number",
      accessor: ({ row }) => {
        return row || "-";
      },
      cellWidth: 1,
      id: "row",
      filterId: "blockIds",
      filter: {
        title: "Block",
        type: filterTypes.MultiSelect,
        selector: blocListSelector
      }
    },
    harvestUnitSort: {
      id: "harvestUnitSort",
      // withSort: true,
      type: "string",
      title: "Harvest unit",
      accessor: ({ harvestUnit }) => {
        return harvestUnit
          ? `${harvestUnit.name} ${harvestUnit.netWeight} Kg`
          : "-";
      },
      cellWidth: 1,
      filterId: "patchIds",
      filter: {
        title: "Patch",
        type: filterTypes.MultiSelect,
        selector: patchListSelector
      }
    },
    price: {
      id: "price",
      withSort: true,
      type: "string",
      title: "Price",
      accessor: ({ price }) => {
        return price || "-";
      },
      cellWidth: 1
      /*   filterId: "row",
        filter: {
          title: "Row",
          type: filterTypes.MultiSelect,
          selector: rowFilterOptions
        } */
    },
    generalTaskId: {
      withSort: true,
      id: "generalTaskId",
      type: "number",
      title: "Task ID",
      accessor: ({ generalTaskId }) => {
        return generalTaskId ? generalTaskId : "-";
      },
      cellWidth: 1,
      filterId: "harvestUnitIds",
      filter: {
        title: "Harvest unit",
        type: filterTypes.MultiSelect,
        selector: harvestUnitOptionsSelector
      }
    },
    internalBinId: {
      id: "internalBinId",
      type: "number",
      title: "Internal bin ID",
      accessor: ({ internalBinId }) => {
        return internalBinId ? internalBinId : "-";
      },
      filterId: "generalTaskIds",
      filter: {
        title: "Task Id",
        type: filterTypes.MultiSelect,
        selector: generalTasksFilterOptions
      },
      cellWidth: 1
    },
    image: {
      id: "image",
      type: "number",
      title: "",
      accessor: "-",
      title: "Image",
      accessor: ({ blockLabel, patchLabel, attachments }) => {
        const imagesUrl =
          (attachments &&
            !!attachments.length &&
            attachments.map(item => ({
              url: item.presignedUrl
            }))) ||
          [];

        return !!imagesUrl.length ? (
          <div className={style.imageContainer}>
            {imagesUrl.slice(0, 3).map(item => {
              return (
                <Button
                  className={style.imageButton}
                  onClick={() => {
                    return (
                      setUrlList(imagesUrl),
                      setblockName(
                        blockLabel && !patchLabel
                          ? `${blockLabel}`
                          : blockLabel &&
                              patchLabel &&
                              `${blockLabel}-${patchLabel}`
                      ),
                      setimageModalOpen(true)
                    );
                  }}
                >
                  <img className={style.image} src={item.url} />
                </Button>
              );
            })}
            {imagesUrl.length > 3 && `+ ${imagesUrl.length - 3}`}
          </div>
        ) : (
          "-"
        );
      },
      cellWidth: 3
    },
    edit: {
      id: "edit",
      withSort: false,
      title: "",
      accessor: ({ id }) => {
        return (
          <div className={style.iconWrapper}>
            <Popup
              inverted
              position="bottom center"
              content={"Edit"}
              size="tiny"
              trigger={
                <div className={style.editButtonWrapper}>
                  <Button
                    onClick={() => editCheck(id)}
                    className={style.editButton}
                    floated="right"
                    icon={<Icon name="edit" />}
                    size="small"
                  />
                </div>
              }
            />
          </div>
        );
      },
      filterId: "supervisor",
      filter: {
        title: "Supervisor",
        type: filterTypes.MultiSelect,
        selector: supervisorsFilterOptions
      },
      cellWidth: 1
    }
  };

  return Object.entries(columnsConfig).map(([id, targetColumn]) => ({
    id,
    ...targetColumn,
    accessor: targetColumn.accessor || id,
    Header: targetColumn.title,
    Cell: targetColumn.Cell || BasicCell,
    ...(targetColumn.sortFields ? { sortFields: targetColumn.sortFields } : {}),
    ...(targetColumn.filter ? { filter: targetColumn.filter } : {})
  }));
};

import { actionType } from "../../constants/GeneralTaskWorkingTimes";

const initialState = {
  data: {
    content: [],
    page: {
      number: 0,
      totalPages: 0,
      totalElements: 0,
      size: 10
    }
  },
  isFetching: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  isArchiving: false,
  error: null
};

export default (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case actionType.GENERAL_TASK_WORKING_TIMES_FETCH_START:
      return {
        ...state,
        isFetching: true
      };

    case actionType.GENERAL_TASK_WORKING_TIMES_FETCH: {
      return {
        ...state,
        data: {
          ...action.payload,
          content: action.payload.content
        },
        isFetching: false
      };
    }

    case actionType.GENERAL_TASK_WORKING_TIMES_FETCH_ERROR: {
      return {
        ...state,
        isFetching: false,
        error: action.payload
      };
    }

    case actionType.GENERAL_TASK_WORKING_TIME_UPDATE_START:
      return {
        ...state,
        isUpdating: true,
        showOffline: true
      };

    case actionType.GENERAL_TASK_WORKING_TIME_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content
        },
        isUpdating: false
      };

    case actionType.GENERAL_TASK_WORKING_TIME_UPDATE_FAIL:
      return {
        ...state,
        isUpdating: false,
        error: action.payload
      };

    case actionType.GENERAL_TASK_WORKING_TIME_CREATE_START:
      return {
        ...state,
        isCreating: true,
        showOffline: true
      };

    case actionType.GENERAL_TASK_WORKING_TIME_CREATE:
      return {
        ...state,
        data: {
          ...state.data,
          content: [state.data.content, action.payload]
        },
        isCreating: false
      };

    case actionType.GENERAL_TASK_WORKING_TIME_CREATE_FAIL:
      return {
        ...state,
        isCreating: false,
        error: action.payload
      };

    case actionType.GENERAL_TASK_WORKING_TIME_DELETE_START:
      return {
        ...state,
        isDeleting: true
      };
    case actionType.GENERAL_TASK_WORKING_TIME_DELETE:
      return {
        ...state,
        isDeleting: false,
        data: {
          ...state?.data,
          content: state?.data?.content?.filter(c => c.id !== action.payload.id)
        }
      };
    case actionType.GENERAL_TASK_WORKING_TIME_DELETE_FAIL:
      return {
        ...state,
        isDeleting: false,
        error: action.payload
      };

    case actionType.GENERAL_TASK_WORKING_TIME_ARCHIVE_START:
      return {
        ...state,
        isArchiving: true
      };
    case actionType.GENERAL_TASK_WORKING_TIME_ARCHIVE:
      return {
        ...state,
        isArchiving: false,
        data: {
          ...state.data,
          content: state.data.content.filter(c => c.id !== action.payload.id)
        }
      };
    case actionType.GENERAL_TASK_WORKING_TIME_ARCHIVE_FAIL:
      return {
        ...state,
        isArchiving: false,
        error: action.payload
      };
    case actionType.CLEAR_ERROR:
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
};

import React, { useCallback } from "react";
import { Icon, Input } from "semantic-ui-react";
import styles from "./ClockIn.module.css";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import useOnClickOutside from "./useOutside";

const DateTimePicker = ({ onChange, value }) => {
  const dateTimeRef = React.useRef();
  const handleDatePickerClose = useCallback(
    () => dateTimeRef.current.closeCalendar(),
    [dateTimeRef]
  );
  useOnClickOutside(dateTimeRef, handleDatePickerClose);

  return (
    <DatePicker
      ref={dateTimeRef}
      onChange={onChange}
      value={value}
      className={styles.dateTimeCalendar}
      format="DD/MM/YYYY HH:mm"
      placeholder="dd/mm/yyyy hh:mm:ss"
      buttons={false}
      plugins={[
        <TimePicker
          style={{ maxWidth: "250px" }}
          disableClock={true}
          hideSeconds
          position="bottom"
        />
      ]}
      render={
        <Input
          placeholder={"dd/mm/yyyy"}
          icon={<Icon name="calendar outline" />}
          iconPosition="right"
        />
      }
    />
  );
};
export default DateTimePicker;

import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import TablePageHolder from "../../components/TablePageHolder/TablePageHolder";
import { getGeneralTasksTableColumns } from "utils/generalTasks";
import {
  fetchGeneralTasksWorkingTimes,
  fetchGeneralTaskList,
  fetchGeneralTask,
  setActiveItemId,
  changeArchivedStatus,
  deleteGeneralTask,
  changeActiveTab,
  setSelectTableType,
  fetchTaskPaymentMethods,
  fetchGeneralTaskTableList,
  updateGeneralTask
} from "actions/Tasks";
import {
  activeItemSelector,
  machinery,
  tasksPriorityFilter
} from "selectors/generalTasks";
import styles from "./Tasks.module.css";
import { useReactToPrint } from "react-to-print";
import TasksModalContent from "./components/TaskModal";
import TaskPrintout from "./TaskPrint/TaskPrint";
import TaskTypeModal from "./components/TaskTypeModal";
import { Button } from "semantic-ui-react";
import { getMachineryList } from "actions/SprayDiary/machinery";
import { fetchMachineryAction } from "actions/Machinery/machineries";
const Tasks = ({
  employeeList,
  machineryList,
  isFetching,
  location,
  route,
  tableData,
  areasList,
  activeItem,
  selectedTab,
  isArchivedView,
  taskPaymentMethods,
  tasksList,
  navigate,
  tableType
}) => {
  const [activeId, setActiveId] = useState(null);
  const [selectedItemsIds, setSelectedItems] = useState([]);
  const [archivedStatus, setArchivedStatus] = useState(false);
  const [taskTypeModal, setTaskTypeModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [activeTab, setactiveTab] = useState("OPEN");
  const [selectMode, setSelectMode] = useState(false);
  const [parameter, setParameter] = useState({});
  const [filters, setFilters] = useState({ cropIds: [] });
  const selectedTask = tableData?.content?.filter(i =>
    activeId ? i.id === activeId : selectedItemsIds.includes(i.id)
  );
  const taskToPrint = tableData?.content?.find(({ id }) => id === activeId);

  const dispatch = useDispatch();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });
  const handleItemClick = (e, { name }) => {
    name !== activeTab &&
      dispatch(changeActiveTab(true)).then(
        () => setactiveTab(name),
        setSelectMode(false)
      );
  };
  const handleTaskIdClick = id => {
    dispatch(
      fetchGeneralTasksWorkingTimes({
        dateFrom: filters.from,
        dateTo: filters.to,
        id: id,
        filters: { taskId: id }
      })
    );
  };
  const changeTaskStatus = (taskID, data) => {
    dispatch(updateGeneralTask(taskID, data));
  };
  useEffect(() => {
    dispatch(changeActiveTab(true));
  }, [tableType]);

  const handleSelectAllItem = data => {
    data?.length
      ? !selectedItemsIds.length
        ? setSelectedItems(data.map(item => item.id))
        : setSelectedItems([])
      : !selectedItemsIds.length
      ? setSelectedItems(tableData?.content?.map(item => item.id))
      : setSelectedItems([]);
  };
  const getData = useCallback(
    async params => {
      await setParameter(params);
      await dispatch(
        fetchGeneralTask(
          { ...params, archived: isArchivedView },
          selectedTab,
          activeTab,
          tableType
        )
      );
      dispatch(setActiveItemId(null));
      setSelectMode(false);
      setActiveId(null);
    },
    [activeTab, fetchGeneralTask, selectedTab, isArchivedView, tableType]
  );

  const getListData = useCallback(
    async params => {
      await dispatch(
        fetchGeneralTaskTableList({ ...params, archived: isArchivedView })
      );
    },
    [fetchGeneralTaskList, isArchivedView, tableType]
  );
  const onRowClick = useCallback(
    (event, row) => {
      dispatch(
        setActiveItemId(typeof event === "number" ? event : row.original.id)
      );
    },
    [activeItem]
  );
  const handleSelectChange = id => {
    selectedItemsIds.includes(id)
      ? setSelectedItems(selectedItemsIds.filter(item => item !== id))
      : setSelectedItems([...selectedItemsIds, id]);
  };
  const onTaskPrint = async id => {
    await setActiveId(id);
    await dispatch(
      fetchGeneralTasksWorkingTimes({
        dateFrom: filters.from,
        dateTo: filters.to,
        id: id,
        filters: { taskId: id }
      })
    );
    handlePrint();
  };
  const onArchiveClick = id => {
    setOpenModal(true);
    setActiveId(id);
  };
  const onDeleteClick = id => {
    setOpenDeleteModal(true);
    setActiveId(id);
  };
  const onTasksArchived = useCallback(() => {
    const tasksIds = activeId ? [activeId] : selectedItemsIds;
    dispatch(
      changeArchivedStatus(
        tasksIds,
        !isArchivedView,
        parameter,
        isArchivedView,
        selectedTab,
        activeTab,
        tableType
      )
    ).then(() => dispatch(fetchGeneralTaskList()));
    setActiveId(null);
    setSelectedItems([]);
    setOpenModal(false);
  }, [selectedItemsIds, tableData.content, activeId]);

  const onDeleteConfirm = useCallback(() => {
    const tasksIds = activeId ? [activeId] : selectedItemsIds;
    dispatch(
      deleteGeneralTask(
        tasksIds,
        parameter,
        isArchivedView,
        selectedTab,
        activeTab,
        tableType
      )
    );
    setActiveId(null);
    setSelectedItems([]);
    setOpenDeleteModal(false);
  }, [selectedItemsIds, tableData.content, activeId]);

  useEffect(() => {
    !taskPaymentMethods.length && dispatch(fetchTaskPaymentMethods());
    !machineryList.length && dispatch(fetchMachineryAction({ unpaged: true }));
  }, []);
  const prioritiesList = useSelector(tasksPriorityFilter).options.map(item => ({
    key: item.key,
    text: item.label,
    value: item.value
  }));
  const tableColumns = getGeneralTasksTableColumns(
    changeTaskStatus,
    prioritiesList,
    onRowClick,
    handleTaskIdClick,
    filters,
    onTaskPrint,
    onDeleteClick,
    isArchivedView,
    activeTab,
    onArchiveClick,
    employeeList,
    areasList,
    isFetching,
    selectMode,
    handleSelectChange,
    selectedItemsIds,
    tableData?.content?.length,
    handleSelectAllItem,
    navigate
  );
  useEffect(() => {
    !selectMode && setSelectedItems([]);
  }, [selectMode]);
  return (
    <>
      <div style={{ display: "none" }}>
        <TaskPrintout
          ref={componentRef}
          task={taskToPrint}
          areasList={areasList}
          employeeList={employeeList}
        />
      </div>
      <TaskTypeModal
        navigate={navigate}
        open={taskTypeModal}
        setOpen={setTaskTypeModal}
      />
      <TasksModalContent
        open={openModal}
        setOpen={setOpenModal}
        title={!isArchivedView ? "Archive" : "Restore"}
        tasks={selectedTask}
        onConfirm={onTasksArchived}
      />
      <TasksModalContent
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        title="Delete"
        tasks={selectedTask}
        onConfirm={onDeleteConfirm}
      />
      <TablePageHolder
        navigate={navigate}
        mainButton={
          !isArchivedView && (
            <Button
              primary
              className={styles.addButton}
              onClick={() => setTaskTypeModal(true)}
            >
              Add new task
            </Button>
          )
        }
        getData={getData}
        getListData={getListData}
        handleTaskIdClick={handleTaskIdClick}
        onRowClick={onRowClick}
        isFetching={isFetching}
        location={location}
        pageTitle={!isArchivedView ? "Tasks" : "Archived tasks"}
        taskNew={true}
        route={route}
        tableColumns={tableColumns}
        setFilters={setFilters}
        isArchivedView={isArchivedView}
        tableData={tableData || []}
        withMoreButton={true}
        withSelection={false}
        activeTab={activeTab}
        handleItemClick={handleItemClick}
        onArchiveClick={onArchiveClick}
        onDeleteClick={onDeleteClick}
        onTaskPrint={onTaskPrint}
        fixed={false}
        wideTableClass={styles.scrollableTableWidth}
        selectMode={selectMode}
        setSelectMode={setSelectMode}
        selectedItemsIds={selectedItemsIds}
        selectedItemsIdsChange={setSelectedItems}
        handleSelectChange={handleSelectChange}
        handleSelectAllItem={handleSelectAllItem}
        taskTableType={tableType}
        setTaskTableType={type => dispatch(setSelectTableType(type))}
        list={tasksList}
      />
    </>
  );
};
Tasks.propTypes = {};
export default connect((state, props) => {
  const isArchivedView = props.location.pathname.includes("archived");
  return {
    isArchivedView,
    selectedTab: state.newTasks.activeTab,
    tableType: state.newTasks.tableType,
    taskPaymentMethods: state.newTasks.taskPaymentMethods,
    tableData: state.newTasks.data || [],
    tasksList: state.newTasks.tableList.content || [],
    isFetching: state.newTasks.isFetching,
    areasList: state.areas.list.content,
    employeeList: state.employee.list.content,
    activeItem: activeItemSelector(state),
    machineryList: state.machinery.data.content
  };
})(Tasks);

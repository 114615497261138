import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Confirm,
  Icon,
  Form,
  Dropdown,
  Label,
  Input
} from "semantic-ui-react";
import { useDispatch, useSelector, connect } from "react-redux";
import { Formik, Field } from "formik";
import styles from "./AddInterval.module.css";
import {
  generalTasksIdsOptions,
  generalTasksAreas
} from "selectors/generalTasks";
import moment from "moment";
import Datetime from "react-datetime";
import { createWorkingTime, fetchGeneralTask } from "actions/WorkingTime";
import EmployeeSection from "./component/EmployeesSection";
import { calculateTimeDifference } from "routes/Tasks/components/helper";
import AreasList from "routes/WorkingTime/components/AreasList";
import DateTimePicker from "../ClockIn/DateTimePicker";

const AddInterval = ({
  params,
  workingTimeCompleted,
  open,
  onClose,
  activeItem,
  areas,
  pageActiveTab
}) => {
  const [secondOpen, setSecondOpen] = useState(false);
  const dispatch = useDispatch();
  const generalTasksIds = useSelector(generalTasksIdsOptions);
  const tasksAreas = useSelector(generalTasksAreas);

  const handleSave = data => {
    const dataToSend = data.map(item => ({
      ...item,
      areas: item.areas.map(a => ({ areaId: a }))
    }));
    Promise.all(dataToSend.map(item => dispatch(createWorkingTime(item))))
      .then(() =>
        dispatch(fetchGeneralTask(params, pageActiveTab, workingTimeCompleted))
      )
      .catch(error => {
        console.error("Error:", error);
      });
    onClose();
  };

  return (
    <Modal
      open={open}
      closeOnDimmerClick={false}
      closeOnEscape={false}
      size="large"
      onClick={e => e.stopPropagation()}
      className={styles.root}
    >
      <Modal.Header className={styles.headerWrapper}>
        <div className={styles.header}>Clock In Clock out</div>
        <div className={styles.icon}>
          <Icon name="close" onClick={onClose} />
        </div>
      </Modal.Header>
      <Formik
        enableReinitialize
        validateOnChange={false}
        initialValues={{
          generalTaskId: "",
          areas: [],
          startAt: "",
          endAt: "",
          lunchInterval: 0,
          children: []
        }}
      >
        {({ values, setFieldValue, setValues, dirty, submitForm, isValid }) => {
          const detailsHoursDifference =
            values?.startAt &&
            values?.endAt &&
            calculateTimeDifference(
              moment(values?.startAt).format("HH:mm"),
              moment(values?.endAt).format("HH:mm"),
              values?.lunchInterval
            );
          const start = moment(values?.startAt);
          let end = moment(values?.endAt);

          if (end.isBefore(start)) {
            end.add(1, "day");
          }

          const diffHours = end.diff(start, "hours", true);

          const validation =
            values.children.length &&
            values.children.every(
              item =>
                item.areas.length &&
                item.generalTaskId &&
                item.employeeId &&
                item.startAt &&
                item.endAt
            );
          const areasOptions = values.generalTaskId
            ? tasksAreas[values.generalTaskId]
            : [];

          return (
            <Form>
              <Modal.Content scrolling className={styles.modalContentContainer}>
                <div className={styles.contentHeader}>Select the task</div>
                <Form.Group widths={16}>
                  <Form.Field required width={8}>
                    <label className={styles.formLabel}>Task </label>
                    <Dropdown
                      text={
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px"
                          }}
                        >
                          {generalTasksIds.find(
                            i => i.value === values.generalTaskId
                          )?.text || ""}
                        </span>
                      }
                      search
                      value={values.generalTaskId}
                      icon={<Icon name="chevron down" />}
                      className={styles.dropdownCustomStyle}
                      options={generalTasksIds}
                      onChange={(_, data) => {
                        return (
                          setFieldValue("generalTaskId", data.value),
                          setFieldValue("areas", []),
                          setFieldValue(
                            "children",
                            values.children.map(i => ({
                              ...i,
                              generalTaskId: data.value
                            }))
                          )
                        );
                      }}
                    />
                  </Form.Field>
                  <Form.Field required width={8}>
                    <label className={styles.formLabel}>Areas </label>
                    <AreasList
                      text={
                        !!values.areas.length && `${values.areas.length} areas`
                      }
                      onChange={(_, data) => {
                        return Array.isArray(data)
                          ? (setFieldValue("areas", [...values.areas, ...data]),
                            setFieldValue(
                              "children",
                              values.children.map(i => ({
                                ...i,
                                areas: [...values.areas, ...data]
                              }))
                            ))
                          : (setFieldValue("areas", [...values.areas, data]),
                            setFieldValue(
                              "children",
                              values.children.map(i => ({
                                ...i,
                                areas: [...values.areas, data]
                              }))
                            ));
                      }}
                      options={areasOptions.filter(
                        a => !values.areas.includes(a.value)
                      )}
                      disabled={!values.generalTaskId}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths={16}>
                  <Form.Field width={8}></Form.Field>
                  {!!values.areas.length && (
                    <Form.Field width={8}>
                      <div className={styles.areasRow}>
                        <span className={styles.areasRowHead}>Areas:</span>
                        {values.areas?.map(item => {
                          return areas.map(({ id, name, parent }) => {
                            return (
                              id === (item?.areaId || item) && (
                                <Label className={styles.addedItem}>
                                  <span className={styles.addedItemValue}>
                                    {" "}
                                    {parent ? `${parent.name}/ ${name}` : name}
                                  </span>
                                  <Icon
                                    className={styles.closeIcon}
                                    name="close"
                                    onClick={() => {
                                      const filteredValues = values.areas?.filter(
                                        id => item !== id
                                      );

                                      setValues({
                                        ...values,
                                        areas: filteredValues
                                      });
                                    }}
                                  />
                                </Label>
                              )
                            );
                          });
                        })}
                      </div>
                    </Form.Field>
                  )}
                </Form.Group>
                <div className={styles.contentHeader}>
                  Specify areas, date and time
                </div>
                <Form.Group widths={16}>
                  <Form.Field required width={8}>
                    <label className={styles.formLabel}>Clock in</label>
                    <div className={styles.calendarWrapper}>
                      <DateTimePicker
                        value={
                          values.startAt
                            ? moment(values.startAt).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )
                            : null
                        }
                        onChange={value => {
                          const date = new Date(value?.toDate?.().toString());
                          const formattedDate = value
                            ? moment(date).format("YYYY-MM-DDTHH:mm:ss")
                            : null;

                          return (
                            setFieldValue("startAt", formattedDate),
                            setFieldValue(
                              "children",
                              values.children.map(i => ({
                                ...i,
                                startAt: formattedDate
                              }))
                            )
                          );
                        }}
                      />
                    </div>
                  </Form.Field>
                  <Form.Field required width={8}>
                    <label className={styles.formLabel}>Lunch interval</label>
                    <Field
                      as={Input}
                      icon={<Icon name="clock outline" />}
                      className={styles.disabledField}
                      name="lunchInterval"
                      value={values.lunchInterval}
                      onChange={(_, { value }) => {
                        setFieldValue("lunchInterval", value);
                        setFieldValue(
                          "children",
                          values.children.map(i => ({
                            ...i,
                            lunchInterval: value
                          }))
                        );
                      }}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths={16}>
                  <Form.Field required width={8}>
                    <label className={styles.formLabel}>Clock out</label>
                    <div className={styles.calendarWrapper}>
                      <DateTimePicker
                        value={
                          values.endAt
                            ? moment(values.endAt).format("DD/MM/YYYY HH:mm:ss")
                            : null
                        }
                        onChange={value => {
                          const date = new Date(value?.toDate?.().toString());
                          const formattedDate = value
                            ? moment(date).format("YYYY-MM-DDTHH:mm:ss")
                            : null;

                          return (
                            setFieldValue("endAt", formattedDate),
                            setFieldValue(
                              "children",
                              values.children.map(i => ({
                                ...i,
                                endAt: formattedDate
                              }))
                            )
                          );
                        }}
                      />
                    </div>
                  </Form.Field>
                  <Form.Field width={8} className={styles.formField}>
                    <label>Working time</label>
                    <Field
                      as={Input}
                      disabled={true}
                      icon={<Icon name="clock outline" />}
                      className={styles.disabledField}
                      name="row"
                      value={
                        detailsHoursDifference
                          ? `${detailsHoursDifference?.hoursDifference}h ${detailsHoursDifference?.minutesDifference}mm`
                          : "0h 00mm"
                      }
                    />
                  </Form.Field>
                </Form.Group>
                {diffHours > 12 && (
                  <Form.Group>
                    <div className={styles.errorMessage}>
                      Clock out time cannot be more than 12 hours after Clock in
                      time.
                    </div>
                  </Form.Group>
                )}
                <div className={styles.contentHeader}>Select employees</div>
                <Form.Group widths={16}>
                  <EmployeeSection
                    activeItem={activeItem}
                    values={values}
                    setFieldValue={setFieldValue}
                    setValues={setValues}
                  />
                </Form.Group>
              </Modal.Content>
              <Modal.Actions className={styles.actionButtons}>
                <Button
                  content="Cancel"
                  color="green"
                  basic
                  onClick={() => {
                    dirty ? setSecondOpen(true) : onClose();
                  }}
                />
                <Button
                  content="Save"
                  color="green"
                  onClick={() => handleSave(values.children)}
                  disabled={!validation || !dirty || diffHours > 12}
                />
              </Modal.Actions>
            </Form>
          );
        }}
      </Formik>
      <Confirm
        className={styles.confirmModal}
        content="Are you sure you want to close modal window"
        open={secondOpen}
        onCancel={() => setSecondOpen(false)}
        onConfirm={() => {
          onClose();
          setSecondOpen(false);
        }}
      />
    </Modal>
  );
};

export default connect((state, props) => {
  return {
    generalTaskIds: state.newTasks.taskIDs,
    areas: state.areas.list.content
  };
})(AddInterval);

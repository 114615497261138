import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin from "@fullcalendar/interaction";
import "./custom-styles.css";
import moment from "moment";
import { Chip } from "routes/Reports/Harvest/components/Chip";
import { employeeViewer } from "routes/Tasks/components/helper";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateGeneralTask } from "actions/Tasks";

const Calendar = ({
  tasksType,
  data,
  areasList,
  employeeList,
  onRowClick,
  handleTaskIdClick,
  navigate
}) => {
  const dispatch = useDispatch();
  const calendarRef = useRef(null);
  const [isLargeMap, setIsLargeMap] = useState({});

  const STORAGE_KEY_VIEW = "calendar_view";
  const STORAGE_KEY_DATE = "calendar_date";

  const handleDatesSet = datesInfo => {
    localStorage.setItem(STORAGE_KEY_VIEW, datesInfo.view.type);
    localStorage.setItem(STORAGE_KEY_DATE, datesInfo.startStr);
  };

  const initialView =
    localStorage.getItem(STORAGE_KEY_VIEW) || "resourceTimelineWeek";
  const initialDate =
    localStorage.getItem(STORAGE_KEY_DATE) || moment().format("YYYY-MM-DD");

  const calendarData = data.content.map(item => ({
    ...item,
    start: item.startAt,
    end: item.endAt,
    resourceId: item.id
  }));

  const resources = data.content.map(item => item);
  const localTime = moment().format("YYYY-MM-DDTHH:mm:ss");

  useEffect(() => {
    document.querySelector(".fc-customPrev-button").innerHTML =
      '<i class="chevron left icon"></i>';
    document.querySelector(".fc-customNext-button").innerHTML =
      '<i class="chevron right icon"></i>';
  }, []);

  const handleUpdateTask = (sendData, taskId) => {
    const dataToSend = {
      name: sendData.name,
      priority: sendData.priority,
      startAt: sendData.startAt,
      endAt: sendData.endAt,
      expireAt: sendData.expireAt,
      assignedTo: sendData.assignedTo,
      attachments: sendData.attachments,
      supervisors: sendData.supervisors,
      contractors: [sendData.creatorId],
      paymentMethod: sendData.paymentMethod,
      description: sendData.description,
      areas: sendData.areas,
      equipments: sendData.equipments,
      round: sendData.round,
      subType: sendData.subType,
      timeBudgeting: sendData.timeBudgeting,
      timeBudgetingPerPlant: sendData.timeBudgetingPerPlant,
      timeBudgetingPerRow: sendData.timeBudgetingPerRow,
      comments: sendData.comments,
      generalTaskTypeId: sendData.generalTaskTypeId,
      generalTaskSubTypeId: sendData.generalTaskSubTypeId,
      status: sendData?.status
    };
    dispatch(updateGeneralTask(taskId, dataToSend));
  };
  const updateIsLargeMap = (eventId, width) => {
    setIsLargeMap(prevState => ({
      ...prevState,
      [eventId]: width > 298
    }));
  };
  return (
    <div className="calendarContainer">
      <FullCalendar
        ref={calendarRef}
        resourceAreaHeaderContent="Task name"
        plugins={[resourceTimelinePlugin, interactionPlugin]}
        initialView={initialView}
        initialDate={initialDate}
        editable={true}
        droppable={true}
        customButtons={{
          customPrev: {
            text: "",
            click: () => calendarRef.current.getApi().prev()
          },
          customNext: {
            text: "",
            click: () => calendarRef.current.getApi().next()
          },
          customToday: {
            text: "Today",
            click: () => calendarRef.current.getApi().today()
          }
        }}
        views={{
          resourceTimelineWeek: {
            type: "resourceTimeline",
            buttonText: "Week",
            duration: { weeks: 1 },
            slotDuration: { days: 1 },
            slotLabelInterval: { days: 1 },
            slotLabelFormat: [{ weekday: "narrow", day: "numeric" }]
          },
          resourceTimelineMonth: {
            type: "resourceTimeline",
            buttonText: "Month",
            duration: { months: 1 },
            slotDuration: { days: 1 },
            slotLabelInterval: { days: 1 },
            slotLabelFormat: [{ day: "numeric" }]
          }
        }}
        duration={{ weeks: 1 }}
        headerToolbar={{
          left: "",
          center: "title",
          right:
            "customPrev customToday customNext resourceTimelineWeek,resourceTimelineMonth"
        }}
        resources={resources}
        resourceLabelContent={arg => {
          const { name, expireAt, status, areas } = arg.resource.extendedProps;
          const completedAreas = areas.filter(item => item.completed);
          const progressPrecent = Math.ceil(
            (completedAreas.length * 100) / areas.length
          );
          const id = arg.resource.id;
          const handleClick = () => {
            if (id) {
              onRowClick(+id);
              handleTaskIdClick(+id);
            }
          };
          return (
            <div className="resourceWrapper">
              <Link
                className="redirectLink"
                onClick={() => handleClick()}
                to={`/tasks/slashing/${id}`}
              >
                <div
                  style={{
                    backgroundColor:
                      status === "OPEN" && expireAt > localTime
                        ? "#9DACBA"
                        : status === "IN_PROGRESS" && expireAt > localTime
                        ? "#F0AC47"
                        : status === "COMPLETED"
                        ? "#59B655"
                        : "#B00020"
                  }}
                  className="precentSection"
                >
                  {expireAt < localTime && status !== "COMPLETED"
                    ? "Exp"
                    : `${progressPrecent}%`}
                </div>
                <div className="taskNameContainer">
                  <p className="taskName"> {name}</p>
                  <p className="taskId">GT-{id}</p>
                </div>
              </Link>
            </div>
          );
        }}
        eventContent={arg => {
          const {
            assignedTo,
            expireAt,
            status,
            areas,
            generalTaskTypeId
          } = arg.event.extendedProps;
          const type = tasksType.find(item => item.id === generalTaskTypeId);
          const filtredArea = areasList
            .filter(a => areas.map(i => i.areaId).includes(a.id))
            .map(area => area.name);
          const id = +arg.event.id;
          const handleClick = () => {
            if (id) {
              onRowClick(id);
              handleTaskIdClick(id);
            }
          };
          const isLarge = isLargeMap[id] || false;
          return (
            <div
              style={{
                height: "53px",
                borderRadius: "8px",
                border:
                  status === "OPEN" && expireAt > localTime
                    ? "1px solid #9DACBA"
                    : status === "IN_PROGRESS" && expireAt > localTime
                    ? "1px solid #F0AC47"
                    : status === "COMPLETED"
                    ? "1px solid #59B655"
                    : "1px solid  #B00020",
                color:
                  status === "OPEN" && expireAt > localTime
                    ? "#9DACBA"
                    : status === "IN_PROGRESS" && expireAt > localTime
                    ? "#F0AC47"
                    : status === "COMPLETED"
                    ? "#59B655"
                    : " #B00020",
                backgroundColor:
                  status === "OPEN" && expireAt > localTime
                    ? "#FFFF"
                    : status === "IN_PROGRESS" && expireAt > localTime
                    ? "#FFFAF2"
                    : status === "COMPLETED"
                    ? "#EBF6E9"
                    : "#FFF4F6"
              }}
              className="eventsWrapper"
            >
              <Link onClick={() => handleClick()} to={`/tasks/slashing/${id}`}>
                <div
                  className="taskNameWrapper"
                  style={{ background: !isLarge ? "#00000008" : "none" }}
                >
                  <div>{type?.name}</div>
                  <div className="chipWrapper">
                    <Chip color="green" label="Areas" items={filtredArea} />
                  </div>
                  <div>{employeeViewer(assignedTo, employeeList)}</div>
                </div>
              </Link>
            </div>
          );
        }}
        events={calendarData}
        datesSet={handleDatesSet}
        eventAllow={(dropInfo, draggedEvent) => {
          return dropInfo.resource.id === draggedEvent._def.resourceIds[0];
        }}
        eventDragStop={info => {
          const calendarApi = calendarRef.current.getApi();
          const event = calendarApi.getEventById(info.event.id);
          if (
            info.event.startStr !== event.startStr ||
            info.event.resourceId !== event.extendedProps.resourceId
          ) {
            info.revert();
          }
        }}
        eventDrop={info => {
          const newStart = info.event.start;
          const newEnd = info.event.end;
          const activeTask = data.content.find(
            item => item.id == info.event.id
          );
          const dataToSend = {
            ...activeTask,
            endAt: moment(newEnd).format("YYYY-MM-DDTHH:mm:ss"),
            startAt: moment(newStart).format("YYYY-MM-DDTHH:mm:ss")
          };
          handleUpdateTask(dataToSend, +info.event.id);
        }}
        eventResize={info => {
          const newStart = info.event.start;
          const newEnd = info.event.end;
          const activeTask = data.content.find(
            item => item.id == info.event.id
          );
          const elementWidth = info.el?.getBoundingClientRect()?.width;
          const eventId = info.event.id;
          if (elementWidth) {
            updateIsLargeMap(eventId, elementWidth);
          }
          const dataToSend = {
            ...activeTask,
            endAt: moment(newEnd).format("YYYY-MM-DDTHH:mm:ss"),
            startAt: moment(newStart).format("YYYY-MM-DDTHH:mm:ss")
          };
          handleUpdateTask(dataToSend, +info.event.id);
        }}
        height="auto"
      />
    </div>
  );
};

export default Calendar;

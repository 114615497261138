import React, { useState } from "react";
import { Icon, Input, Dropdown } from "semantic-ui-react";
import styles from "../AddEditTask/AddTask.module.css";
import { map } from "lodash";

const DropdownWithSearch = ({
  text,
  seaction,
  placeholder,
  value,
  className,
  list,
  type,
  handleMenuItemClick,
  values,
  setFieldValue
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const filtered = options =>
    options?.filter(item =>
      item.text.toLowerCase().includes(searchQuery.toLowerCase())
    );

  return (
    <Dropdown
      closeOnChange={false}
      selection
      fluid
      multiple
      search
      onClose={() => (setSearchQuery(""), setIsOpen(false))}
      text={text}
      onOpen={() => handleMenuItemClick(seaction)}
      placeholder={placeholder}
      value={value}
      icon={<Icon name="chevron down" />}
      className={className}
    >
      <Dropdown.Menu className={styles.menuContainer}>
        <div className={styles.searchContainer}>
          <Input
            placeholder="Search"
            icon="search"
            iconPosition="left"
            className={styles.searchInput}
            onClick={e => e.stopPropagation()}
            onChange={e => setSearchQuery(e.target.value)}
            value={searchQuery}
          />
        </div>
        <Dropdown.Menu scrolling className={styles.listMenu}>
          {map(
            (filtered(list) || list)
              .sort((a, b) => {
                const nameA = a.label?.trim().toLowerCase(),
                  nameB = b.label?.trim().toLowerCase();
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
              })
              .filter(a => !values[type]?.includes(a.value)),
            option => (
              <Dropdown.Item
                key={option?.value}
                value={option?.value}
                text={option?.text}
                onClick={(_, data) =>
                  setFieldValue(type, [...values[type], data.value])
                }
              />
            )
          )}
        </Dropdown.Menu>
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default DropdownWithSearch;
